






















import { Component, Vue } from "vue-property-decorator";
import { mapGetters, mapState } from 'vuex';
import { Action } from 'vuex-class';
import TmallCredentials from '@/models/TmallCredentials';

@Component({
  computed: {
    ...mapGetters('tmall', {
      credentials: 'getCredentials'
    })
  }
})
export default class TmallCredentialsPage extends Vue {

  protected credentials!: TmallCredentials
  loading: Boolean = true
  
  @Action('fetchCredentials', { namespace: 'tmall' }) fetchCredentials: any;
  @Action('updateCredentials', { namespace: 'tmall' }) updateCredentials: any;

  async mounted() {
    try {
      await this.fetchCredentials()
    } catch (err) {
    } finally {
      this.loading = false
    }
  }

  submit() {
    this.updateCredentials({credentials: this.credentials}).then((result: any) => {
      this.$router.push({ name: 'tmall-login' })
    })
  }
}
